ul {
  padding: 0;
  margin: 0;
}
#root li {
  list-style: none;
  display: inline-block;
}
#root a:hover, a:visited, a:link, a:active {
  color: inherit!important;
  text-decoration: none!important;
}
#root .ant-btn-primary-disabled, .ant-btn-primary.disabled, .ant-btn-primary[disabled], .ant-btn-primary-disabled:hover, .ant-btn-primary.disabled:hover, .ant-btn-primary[disabled]:hover, .ant-btn-primary-disabled:focus, .ant-btn-primary.disabled:focus, .ant-btn-primary[disabled]:focus, .ant-btn-primary-disabled:active, .ant-btn-primary.disabled:active, .ant-btn-primary[disabled]:active, .ant-btn-primary-disabled.active, .ant-btn-primary.disabled.active, .ant-btn-primary[disabled].active {
  background-color: #ccc8 !important;
}

#root .ant-card {
  background-color: #0000;
  
}
#root .ant-card-head {
  border-bottom: 1px solid #0002;
}
#root .page-me .ant-card-head {
  border-bottom: 1px solid #ddd6;
}
#root .page-board .ant-card-head {
  border-bottom: 1px solid #bbb6;
}
#root .ant-timeline-item-head {
  background-color: #fff0;
}

#root .ant-divider {
  background-color: #0001;
} 
#root .detail .ant-divider {
  margin: 15px 0;
}

#root .ant-anchor-link {
  padding: 4.5px 0 4.5px 10px;
}
#root .ant-anchor-link-title {
  margin-bottom: 4px;
}
#root .ant-anchor-link-title:only-child {
  margin-bottom: 0px;
}


.detail Pre {
  overflow: auto !important;
  overflow: overlay !important;
  background: #fffa !important;
  padding: 10px 15px!important;
  border-radius: 5px;
  font-size: 13px;
}
.detail table {
  border-radius: 2px;
  display: block;
  background-color: #fffa;
  overflow: auto;
  overflow: overlay;
  margin: 1em 0;
}
.detail thead {
  border-bottom: 1px solid #5551;
}
.detail tr:nth-of-type(even) {
  background-color: #7771;
}
.detail td,th {
  padding: 6px 4px;
}
.detail blockquote {
    padding: 15px;
    background-color: #f9f9f9;
    border-left: 6px solid #2786df;
    word-break: break-word;
    margin: 0 0 20px;
}
.detail blockquote p {
  margin: 0;
}
.detail p img {
  max-width:  100%;
}
@keyframes show {
  0% {   /*0%*/
    transform: translateY(-400px);
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {    /*100%*/
    transform: translateY(0px);
    opacity: 1;
  }
}